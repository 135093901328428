<template>
    <div>
        <table class="table-row mt-10">
            <tbody>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 구분</th>
                    <td>
                        {{comp.info.ctype}}
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 기간</th>
                    <td>
                        {{comp.info.sdate}} ~ {{comp.info.edate}}
                        <div class="color-1">
                            ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                            ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                        </div>
                    </td>
                </tr>
                <tr>
                  <th width="150"> 잔여연차개수</th>
                  <td>
                    {{comp.cur_vacation }}개
                    <div class="color-1">
                      ※ 잔여 연차를 초과하여 연차 사용시 (마이너스 연차) 해당 월 급여에서 공제 처리됩니다.
                    </div>
                  </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
                    <td><span class="al_memo">{{comp.info.memo}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import axios from "@/plugins/axios";

export default {
    name: 'AnnualLeave',
    components: {
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            idx_staff:0,
            info: {
                ctype : "",
                sdate : "",
                edate : "",
                memo : "",
                count : 0,
            },
            cur_vacation : 0,
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
            Init : () => {
              let params = {
                idx:comp.idx_staff
              };
              axios.get("/rest/personal/getVacationCnt", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  comp.cur_vacation = res.data.cur_vacation;
                }
              });
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
                comp.idx_staff = props.modelValue.idx_staff;
              comp.Init();
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .al_memo {
        white-space:pre-line;
        line-height:180%;
    }
</style>