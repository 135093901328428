<template>
    <div>
        <SelectClassPopup v-if="comp.showClassSelector" @selected="comp.setClass" @close="comp.showClassSelector=false"></SelectClassPopup>
        <table class="table-row mt-10">
            <colgroup>
                <col width="150">
                <col width="*">
                <col width="150">
                <col width="*">
            </colgroup>
            <tbody>
                <tr>
                    <th><span class="txt-red">*</span> 총 금액</th>
                    <td>
                        {{comp.intToCost(comp.info.amount)}}
                    </td>
                    <th><span class="txt-red">*</span> 지출일</th>
                    <td>
                        
                        <span class="ml-5 mr-20"> {{comp.info.cday}}일</span>
                    </td>
                </tr>
                <tr>
                    <th><span class="txt-red">*</span> 법인구분</th>
                    <td colspan="3">
                        {{comp.info.corporation}}
                    </td>
                </tr>
                <tr>
                    <th><span class="txt-red">*</span> 지출증빙</th>
                    <td colspan="3">
                        {{comp.info.proof}}
                    </td>
                </tr>
                <tr>
                    <th>특이사항</th>
                    <td colspan="3" class="al_memo">
                        {{comp.info.memo}}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="block-title mt-50 mb-20">강사지급금액 상세</div>
        <div v-for="(itm, idx) in comp.items" :key="idx">
            <table class="table-row mb-10">
                <colgroup>
                    <col width="150">
                    <col width="*">
                    <col width="150">
                    <col width="*">
                </colgroup>
                <tbody>
                    <tr>
                        <th>ITM 클래스</th>
                        <td colspan="3">
                            <div class="float-left w-85per">{{itm.classname}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th>매니저</th>
                        <td>
                            {{itm.hq_name}}
                        </td>
                        <th>강사이름</th>
                        <td>
                            {{itm.tutor_name}}
                        </td>
                    </tr>
                    <tr>
                        <th><span class="txt-red">*</span> 강사지급금액</th>
                        <td>
                            {{comp.intToCost(itm.cost)}}
                        </td>
                        <th>출석누락일</th>
                        <td>
                            <span class="ml-10">{{itm.missday}}</span>
                        </td>
                    </tr>
                    <tr>
                        <th><span class="txt-red">*</span> 은행/계좌번호</th>
                        <td>
                          <span v-if="itm.bank!=='기타(직접입력)'">
                            {{itm.bank}}
                          </span>
                            <span class="ml-10">{{itm.account_number}}</span>
                        </td>
                        <th><span class="txt-red">*</span> 예금주</th>
                        <td>
                            {{itm.account_name}}
                        </td>
                    </tr>
                    <tr>
                        <th>비고(사유기재)</th>
                        <td colspan="3" class="al_memo">
                            {{itm.memo}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="color-1 mt-50 mb-20">※ 증빙서류 미첨부 및 작성 양식이 정확히 기재되어있지 않을 경우 지급이 지연될 수 있습니다.</div>
    </div>
</template>
<script>
import { onMounted, reactive, watch, computed } from 'vue'
import SelectClassPopup from '@/components/popup/payment/SelectClassPopup.vue'

export default {
    name: 'AmountPaid',
    components: {
        SelectClassPopup
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const comp = reactive({
            showClassSelector: false,
            selectedClassSelector: 0,
            sdate:"",
            info: {
                amount: computed(() => {
                    let n =0;
                    for(let i=0;i<comp.items.length;i++) {
                        n = n + parseInt(comp.items[i].cost);
                    }
                    return n;
                }),
                cday: 0,
                corporation: "",
                proof: "",
                memo: ""
            },
            items: [{
                classname:"",
                idx_hq: 0,
                hq_name: "",
                idx_tutor: 0,
                tutor_name: "",
                cost: 0,
                day: 0,
                bank: "",
                account_number : "",
                memo:"",
            }],

            removeItem: () => {
                comp.items.pop();
            },
            showClass: (idx) => {
                comp.selectedClassSelector = idx;
                comp.showClassSelector = true;
            },
            addItem: () => {
                comp.items.push({
                    classname:"",
                    idx_hq: 0,
                    hq_name: "",
                    idx_tutor: 0,
                    tutor_name: "",
                    cost: 0,
                    day: 0,
                    bank: "",
                    account_number : "",
                    memo: ""
                });
            },
            setClass: (sltClass) => {
                comp.items[comp.selectedClassSelector].classname = sltClass.classname;
                comp.items[comp.selectedClassSelector].idx_hq = sltClass.hq_idx;
                comp.items[comp.selectedClassSelector].hq_name = sltClass.hq_name;
                comp.items[comp.selectedClassSelector].tutor_name = sltClass.tutor_name;
            },
            costChange: () => {
                console.info("AA");
            },
            update: () => {
                emit('update:modelValue', comp.dateToString());
            },
            dateToString() {
                return [comp.sdate.getFullYear(),((comp.sdate.getMonth() + 1)>9 ? '' : '0') + (comp.sdate.getMonth() + 1),(comp.sdate.getDate()>9 ? '' : '0') + comp.sdate.getDate()].join('-');
            },
            intToCost(price) {
                let cost = price.toString().replace(/,/g, "");
                cost = parseFloat(cost);
                if(isNaN(cost)) {
                    cost = 0;
                }
                return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.sdate = nVal;
            }

        });

        onMounted(() => {
            // Mounted
            comp.info.cday = props.modelValue.info.cday;
            comp.info.corporation = props.modelValue.info.corporation;
            comp.info.proof = props.modelValue.info.proof;
            comp.info.memo = props.modelValue.info.memo;
            comp.items = props.modelValue.list;
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .al_memo {
        white-space:pre-line;
        line-height:180%;
    }
</style>