<template>
    <div>
        <table class="table-row mt-10">
            <tbody>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 구분</th>
                    <td>
                        {{comp.info.ctype}}
                    </td>
                </tr>
                <tr>
                    <th width="150">선택</th>
                    <td>
                        {{comp.info.reason}}
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 기간</th>
                    <td>
                        {{comp.info.sdate}} ~ {{comp.info.edate}}
                        <div class="color-1">
                            ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                            ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                        </div>
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
                    <td><span class="al_memo">{{comp.info.memo}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'

export default {
    name: 'AnnualLeave',
    components: {
        
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            info: {
                ctype : "",
                reason : "",
                sdate : "",
                edate : "",
                memo : "",
            },
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .al_memo {
        white-space:pre-line;
        line-height:180%;
    }
</style>