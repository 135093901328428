<template>
    <div>
        <table class="table-row mt-10">
            <tbody>
            <tr>
              <th width="150"> 구분</th>
              <td> 연차 취소/수정 신청서 </td>
            </tr>
            <tr>
              <th width="150"><span class="txt-red">*</span> 기간</th>
              <td>
                <table class="mt-20 mb-10 leave-border">
                  <tr class="back-grey">
                    <td>날짜</td>
                    <td v-for="(irow, i) in comp.info.list.slice(0, 14)" :key="i" class="font-12">
                      {{ irow.udateText }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="mb-5"> 연차 </div>
                      <div class="mb-5"> 오전 </div>
                      <div class="mb-5"> 오후 </div>
                      <div class="mb-5"> 취소 </div>
                    </td>
                    <td class="txt-center" v-for="(irow, i) in comp.info.list.slice(0, 14)" :key="i" >
                      <label><input type="radio" v-model="irow.ctype" value="연차" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="오전" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="오후" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="취소" disabled></label><br>
                    </td>
                  </tr>

                  <tr class="back-grey"  v-if="comp.info.list.length > 15" >
                    <td>날짜</td>
                    <td v-for="(irow, i) in comp.info.list.slice(14)" :key="i" class="font-12">
                      {{ irow.udateText }}
                    </td>
                  </tr>
                  <tr  v-if="comp.info.list.length > 15">
                    <td>
                      <div class="mb-5"> 연차 </div>
                      <div class="mb-5"> 오전 </div>
                      <div class="mb-5"> 오후 </div>
                      <div class="mb-5"> 취소 </div>
                    </td>
                    <td class="txt-center" v-for="(irow, i) in comp.info.list.slice(14)" :key="i" >
                      <label><input type="radio" v-model="irow.ctype" value="연차" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="오전" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="오후" disabled></label><br>
                      <label><input type="radio" v-model="irow.ctype" value="취소" disabled></label><br>
                    </td>
                  </tr>
                </table>
                <div class="color-1">
                  ※ 법정공휴일 또는 주말은 자동 제외되어 표기됩니다.
                </div>
              </td>
            </tr>
            <tr>
              <th width="150"> 잔여연차개수</th>
              <td>
                {{ comp.cur_vacation }}개
                <div class="color-1">
                  ※ 잔여 연차를 초과하여 연차 사용시 (마이너스 연차) 해당 월 급여에서 공제 처리됩니다.
                </div>
              </td>
            </tr>
            <tr>
              <th width="150"> 신청사유</th>
              <td><span class="al_memo">{{ comp.info.memo }}</span></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import axios from "@/plugins/axios";

export default {
    name: 'AnnualLeaveCancel',
    components: {
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            idx_staff:0,
            info: {
                ctype : "",
                sdate : "",
                edate : "",
                memo : "",
                count : 0,
                list : [],
            },
            cur_vacation : 0,
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
            Init : () => {
              let params = {
                idx:comp.idx_staff
              };
              axios.get("/rest/personal/getVacationCnt", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  comp.cur_vacation = res.data.cur_vacation;
                }
              });
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal && props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
                comp.idx_staff = props.modelValue.idx_staff;
              comp.Init();
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
    .al_memo {
        white-space:pre-line;
        line-height:180%;
    }
</style>