<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-500px">
            <div>
                <p class="title">{{pop.title}} 사유</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <textarea class="w-100per h-100px pd-10" v-model="pop.info.message"></textarea>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-right" @click="pop.sign()">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';

export default {
    name: 'ReasonPopup',
    props: {
        idx: {
            type: [Number, String],
            default: 0,
        },
        title: {
            type: String,
            default: "보류",
        }
    },
    emits: [ 'close', 'change' ],
    setup(props, {emit}) {
        const toast = useToast()
        const pop = reactive({
            title : "보류",
            info : {
                message : ""
            },
            onClose: () => {
                emit('close');
            },
            sign : () => {
                if(pop.info.message.trim()<='')  {
                    toast.error("사유를 입력해주세요.");
                    return;
                }

                let params = {
                    idx : pop.idx,
                    sign : pop.title,
                    memo : pop.info.message
                };
                axios.post("/rest/documents/setSignDefer", params).then((res) => {
                    if( res.data.err == 0 ){
                        emit('change');
                        emit('close');
                        return;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },


        });

        onMounted(() => {
            // Mounted
            pop.title = props.title;
            pop.idx = props.idx;
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>