<template>
  <div class="home">
    <CarrotHeader></CarrotHeader>
    <div class="sub-wrap">
      <div class="sub-wrap-title">
        <p>전자결재</p>
      </div>

      <!-- 서브페이지 내용 -->
      <div class="con-wrap">
        <CarrotTitle title="기안상세">
          <div
            class="btn-ibbox title-btnbox float-right"
            v-if="draft.use_sign == 'Y' && draft.status != '반려'"
          >
            <button class="btn-default" @click="draft.sign('결재')">
              결재
            </button>
            <button class="btn-default ml-10" @click="draft.sign_pop = '반려'">
              반려
            </button>
          </div>
        </CarrotTitle>
        <ReasonPopup
          :idx="draft.idx"
          :title="draft.sign_pop"
          v-if="draft.sign_pop != ''"
          @close="draft.sign_pop = ''"
          @change="draft.getDocumentInfo(draft.idx)"
        ></ReasonPopup>
        <div class="board">
          <div class="mt-40">
            <div>
              <div class="dp-table w-100per">
                <div class="float-left w-50per pr-25">
                  <table class="table-row">
                    <tbody>
                      <tr>
                        <th width="150">양식이름</th>
                        <td>{{ draft.form }}</td>
                      </tr>
                      <tr>
                        <th width="150">기안자</th>
                        <td>
                          {{ draft.docinfo.ename }} ({{ draft.docinfo.kname }})
                        </td>
                      </tr>
                      <tr>
                        <th width="150">소속</th>
                        <td>
                          {{ draft.docinfo.sector_name
                          }}{{ draft.docinfo.deptname }}
                        </td>
                      </tr>
                      <tr>
                        <th width="150">작성일</th>
                        <td>{{ draft.docinfo.createdate }}</td>
                      </tr>
                      <tr>
                        <th width="150">문서번호</th>
                        <td>{{ draft.docinfo.no }}</td>
                      </tr>
                      <tr>
                        <th width="150">보안</th>
                        <td>
                          <i
                            class="far fa-check-square"
                            v-if="draft.is_private"
                          ></i
                          ><i class="far fa-square" v-if="!draft.is_private"></i
                          ><span class="ml-5"> Private</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="float-right w-50per pl-25">
                  <table class="table-col">
                    <colgroup>
                      <col :span="draft.line_staff.length" />
                      <!--결재지정한 수만큼(thead th수)-->
                    </colgroup>
                    <thead>
                      <tr>
                        <th v-for="s in draft.line_staff" :key="s.idx">
                          {{ s.position }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          class="document_td"
                          v-for="s in draft.line_staff"
                          :key="s.idx"
                        >
                          {{ s.ename }}({{ s.kname }})
                        </td>
                      </tr>
                      <tr>
                        <td v-for="s in draft.line_staff" :key="s.idx">
                          {{ s.sign }}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table-row mt-45">
                    <colgroup>
                      <col width="17%" />
                      <col width="36%" />
                      <col width="*" />
                      <col width="17%" />
                    </colgroup>
                    <tbody>
                      <tr v-for="(s, idx) in draft.cc_staff" :key="s.idx">
                        <th v-if="idx == 0" :rowspan="draft.cc_staff.length">
                          참조
                        </th>
                        <td>{{ s.deptname }}</td>
                        <td>{{ s.ename }}({{ s.kname }})</td>
                        <td>
                          <span v-if="s.is_check == 'N'">미확인</span
                          ><span v-if="s.is_check == 'Y'">{{
                            s.checkdate
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <table class="table-row mt-45">
                <tbody>
                  <tr>
                    <th width="150">제목</th>
                    <td>
                      {{ draft.title }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <AmountPaidViewer
                v-if="draft.form_type == 'AmountPaid'"
                ref="amountPaid"
                v-model="draft.json_data"
              ></AmountPaidViewer>
              <AnnualLeaveViewer
                v-if="draft.form_type == 'AnnualLeave'"
                ref="annualLeave"
                v-model="draft.json_data"
              ></AnnualLeaveViewer>

              <AnnualLeaveCancelViewer
                v-if="draft.form_type == 'AnnualLeaveCancel'"
                ref="annualLeaveCancel"
                v-model="draft.json_data"
              ></AnnualLeaveCancelViewer>
              <AnnualLeaveOutingViewer
                v-if="draft.form_type == 'AnnualLeaveOuting'"
                ref="annualLeaveOuting"
                v-model="draft.json_data"
              ></AnnualLeaveOutingViewer>
              <AnnualLeaveNewViewer
                v-if="draft.form_type == 'AnnualLeaveNew'"
                ref="annualLeaveNew"
                v-model="draft.json_data"
              ></AnnualLeaveNewViewer>
              <AnnualLeaveExtraViewer
                v-if="draft.form_type == 'AnnualLeaveExtra'"
                v-model="draft.json_data"
              ></AnnualLeaveExtraViewer>

              <ExtraPay
                v-if="draft.form_type == 'ExtraPay'"
                mode="View"
                ref="extraPay"
                v-model="draft.json_data"
              ></ExtraPay>
              <OvertimePay
                v-if="draft.form_type == 'OvertimePay'"
                mode="View"
                ref="overtimePay"
                v-model="draft.json_data"
              ></OvertimePay>
              <WorkAtHome
                v-if="draft.form_type == 'WorkAtHome'"
                mode="View"
                v-model="draft.json_data"
              ></WorkAtHome>
              <ExternalBook
                v-if="draft.form_type == 'ExternalBook'"
                mode="View"
                v-model="draft.json_data"
              ></ExternalBook>

              <div class="document-view-box" v-html="draft.content"></div>

              <div class="mt-45 txt-red">
                ※ 결재완료 후 6개월이 경과된 문서의 첨부파일은 자동으로
                삭제됩니다.
              </div>
              <table class="table-row" v-if="draft.file == 'Y'">
                <tbody>
                  <tr>
                    <th width="150">파일첨부<br />(파일별 최대 10M)</th>
                    <td>
                      <div v-if="draft.file1 != ''">
                        <a
                          :href="draft.file1_url"
                          :download="draft.file1"
                          target="new"
                          >{{ draft.file1 }}</a
                        >
                      </div>
                      <div v-if="draft.file2 != ''">
                        <a
                          :href="draft.file2_url"
                          :download="draft.file2"
                          target="new"
                          >{{ draft.file2 }}</a
                        >
                      </div>
                      <div v-if="draft.file3 != ''">
                        <a
                          :href="draft.file3_url"
                          :download="draft.file3"
                          target="new"
                          >{{ draft.file3 }}</a
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <router-link to="/documents/AllDocumentList"
                ><button class="btn-default float-left mt-30">
                  목록
                </button></router-link
              >
              <button
                class="btn-default float-left mt-30 ml-10"
                @click="comm.docPrint"
              >
                인쇄
              </button>
              <button
                class="btn-default float-left mt-30 ml-10"
                @click="draft.delete"
                v-if="draft.authority == 'Y' && draft.status == '진행중'"
              >
                삭제
              </button>
              <button
                class="btn-default float-right mt-30"
                @click="draft.modify"
                v-if="draft.authority == 'Y'"
              >
                수정
              </button>
              <div class="clear"></div>
            </div>
          </div>

          <div v-if="cmt.isLoaded == true" class="mt-40 comment-list">
            <carrot-cmt-editor
              ctype="documents"
              v-model="draft.idx"
              @input="cmt.reloadList()"
              class="h-150px"
            ></carrot-cmt-editor>
            <carrot-cmt-list
              :reload="cmt.isLoadCmtList"
              ctype="documents"
              :index="draft.idx"
              v-model:cnt.sync="cmt.cnt_cmt"
            ></carrot-cmt-list>
          </div>
        </div>
      </div>
      <!-- 서브페이지 내용 끝 -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, ref } from "vue";

import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";

import AmountPaidViewer from "@/components/drafting/AmountPaidViewer.vue";
import AnnualLeaveViewer from "@/components/drafting/AnnualLeaveViewer.vue";

import AnnualLeaveNewViewer from "@/components/drafting/AnnualLeaveNewViewer.vue";
import AnnualLeaveOutingViewer from "@/components/drafting/AnnualLeaveOutingViewer.vue";
import AnnualLeaveCancelViewer from "@/components/drafting/AnnualLeaveCancelViewer.vue";

import AnnualLeaveExtraViewer from "@/components/drafting/AnnualLeaveExtraViewer.vue";
import WorkAtHome from "@/components/drafting/WorkAtHome.vue";
import ExternalBook from "@/components/drafting/ExternalBook.vue";

import ExtraPay from "@/components/drafting/ExtraPay.vue";
import OvertimePay from "@/components/drafting/OvertimePay.vue";

import CarrotCmtEditor from "@/components/common/CarrotCmtEditor.vue";
import CarrotCmtList from "@/components/common/CarrotCmtList.vue";
import ReasonPopup from "@/components/popup/payment/ReasonPopup.vue";
import { useStore } from "vuex";

export default {
  components: {
    CarrotCmtEditor,
    CarrotCmtList,
    AmountPaidViewer,
    ExtraPay,
    OvertimePay,
    ReasonPopup,
    AnnualLeaveViewer,
    AnnualLeaveExtraViewer,
    WorkAtHome,
    ExternalBook,
    AnnualLeaveNewViewer,
    AnnualLeaveOutingViewer,
    AnnualLeaveCancelViewer,
  },
  setup() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const amountPaid = ref(null);
    const extraPay = ref(null);

    const draft = reactive({
      // Popup 노출 여부
      isShow: false,
      showForm: false,
      showLoadPopup: false,
      showApprovalLine: false,
      showApprovalLineLoad: false,

      sign_pop: "",

      // 양식 데이터
      docinfo: {
        ename: "",
        kname: "",
        deptname: "",
        no: "",
        createdate: "",
        sector_name: "",
      },
      use_sign: "N",
      authority: "N",
      idx: 0,
      is_private: false,
      form_kind: "",
      form_name: "",
      title: "",
      content: "",
      json_data: "",
      file: "",

      line_staff: [],
      cc_staff: [],
      modify: () => {
        router.push({
          name: "documents-DraftingModify",
          params: { id: draft.idx },
        });
      },

      delete: () => {
        Swal.fire({
          title: "전자결재",
          text: "현재 문서를 삭제하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            let params = {
              idx: draft.idx,
            };
            axios
              .post("/rest/documents/delDocumentInfo", params)
              .then((res) => {
                if (res.data.err == 0) {
                  router.push({
                    name: "documents-AllDocumentList",
                  });
                  return;
                } else {
                  if (res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
          }
        });
      },

      sign: (sign) => {
        if (draft.idx <= 0) {
          toast.error("잘못된 접근입니다.");
          return;
        }

        Swal.fire({
          title: "전자결재",
          text: "결재하시겠습니까?",
          showCancelButton: true,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            let params = {
              idx: draft.idx,
              sign: sign,
            };
            axios.post("/rest/documents/setSign", params).then((res) => {
              if (res.data.err == 0) {
                draft.getDocumentInfo(route.params.id);
                return;
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          }
        });
      },

      getDocumentInfo: (idx) => {
        let params = {
          idx: idx,
          mode: "view",
        };
        axios
          .get("/rest/documents/getDocumentInfo", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              draft.idx = res.data.info.idx;
              draft.title = res.data.info.title;
              draft.form_kind = res.data.info.category;
              draft.form_name = res.data.info.form;
              draft.form_type = res.data.info.form_type;
              draft.is_private = res.data.info.is_private == "Y" ? true : false;
              draft.content = res.data.info.content;
              if (res.data.info.json != "") {
                draft.json_data = JSON.parse(res.data.info.json);
                draft.json_data.idx_staff = res.data.info.idx_staff;
                draft.json_data.position = res.data.info.position;
              }
              draft.docinfo.no = res.data.info.code;
              draft.docinfo.kname = res.data.info.staff_kname;
              draft.docinfo.ename = res.data.info.staff_ename;
              draft.docinfo.deptname = res.data.info.deptname;
              if (res.data.info.sector_name !== "")
                draft.docinfo.sector_name = res.data.info.sector_name + "/";
              draft.docinfo.createdate = res.data.info.createdate;
              draft.form = res.data.info.form;
              draft.file = res.data.info.file;
              draft.file1 = res.data.info.file1;
              draft.file1_url = res.data.info.file1_url;
              draft.file2 = res.data.info.file2;
              draft.file2_url = res.data.info.file2_url;
              draft.file3 = res.data.info.file3;
              draft.file3_url = res.data.info.file3_url;
              draft.use_sign = res.data.info.use_sign;
              draft.sign_step = res.data.info.sign_step;
              draft.status = res.data.info.status;

              draft.authority = res.data.info.authority;
              if (store.state.isMaster) {
                draft.authority = "Y";
              }

              draft.line_staff = res.data.line_staff;
              draft.cc_staff = res.data.cc_staff;

              cmt.isLoaded = true;
              cmt.reloadList();
            } else {
              if (res.data.err == 201) {
                router.push({
                  name: "documents-AllDocumentList",
                });
                return;
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
              }
            }
          });
      },
    });

    const cmt = reactive({
      isLoaded: false,
      isLoadCmtList: false,
      isMode: "",
      selected: 0,

      page: 1,
      cnt_cmt: 0,
      newsmile: "",
      newtext: "",
      modsmile: "",
      modtext: "",
      list: [],
      total: 0,

      reloadList: () => {
        cmt.isLoadCmtList = false;
        setTimeout(() => {
          cmt.isLoadCmtList = true;
        }, 100);
      },
    });
    const comm = reactive({
      isPrinted: false,
      docPrint: () => {
        window.print();
        comm.isPrinted = true;
      },
    });

    onMounted(() => {
      // Mounted
      draft.getDocumentInfo(route.params.id);
    });

    return { draft, amountPaid, extraPay, cmt, comm, store };
  },
};
</script>

<style lang="scss">
.document-view-box {
  table {
    td {
      font-size: 13px;
      padding: 5px;
      border: 1px solid #ddd;
    }
  }
}
.draftbox {
  width: 100%;
  height: 500px;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    size: 210mm, 297mm;
    margin: 0;
    padding: 0;
  }
  .main-navi-wrap,
  .sub-navi-wrap,
  .sub-wrap-title,
  .btn-default,
  .comment-list {
    display: none;
    height: 0px;
  }
  .sub-wrap {
    margin-top: 0px;
  }
  .con-top {
    margin-top: 0px;
    display: none;
  }
  .mt-40 {
    margin-top: 0px;
  }
}
</style>
